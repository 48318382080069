import React, { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import "./css/HomeHeader.css";
import Typography from "@material-ui/core/Typography";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import i18n from "../i18n";
import {
    usePopupState,
    bindHover,
    bindPopover,
} from "material-ui-popup-state/hooks";

import { Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const HomeHeader = ({ isFixedBarAlwaysOpen }) => {
    
    const { t } = useTranslation();
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 980);
    const menuRef = useRef(null); // Reference for the menu container

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsHamburgerMenuOpen(false); // Close the menu if clicked outside
            }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 980);
        };

        window.addEventListener('resize', handleResize);
        
        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const popupState = usePopupState({
        variant: "popover",
        popupId: "demoPopover",
        closeOnClickAway: true,
        disableAutoFocus: true,
    });

    // const [fixNavbar, setFixNavbar] = useState(
    //   isFixedBarAlwaysOpen ? "fixed-navbar" : "header-nav-block-none"
    // );
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
    const handleHamburgerClick = () => {
        setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
        if (isHamburgerMenuOpen) {
        }
    };

    const handleCloseHamburgerMenu = () => {
        setIsHamburgerMenuOpen(false);
    };

    const handleLanguageChange = (e) => {
        localStorage.setItem('language', e.target.value); // Store language in localStorage
        i18n.changeLanguage(e.target.value);
        //close the hamburger menu
        setIsHamburgerMenuOpen(false);
    };

    const handlePopoverItemClick = () => {
        popupState.close();
        handleCloseHamburgerMenu();
    };

    const normalizeLanguage = (language) => language.split('-')[0]; // Extract base language

    useEffect(() => {
        const detectedLanguage = normalizeLanguage(i18n.language || "tr");
        if (!Object.keys(i18n.options.resources).includes(detectedLanguage)) {
            i18n.changeLanguage("tr"); // Fallback if normalized language isn't supported
        } else {
            i18n.changeLanguage(detectedLanguage);
        }
    }, []);

    return (
        <>
            {/* <header className="navbar" id="nav" style={{backgroundImage: "url(" + background+ ")"}}> */}
            <header className="navbar " id="nav">
                <nav
                    ref={menuRef} // Attach the ref here
                    className={
                        "header-nav fixed-navbar" +
                        (isHamburgerMenuOpen ? " header-nav-backgorunded" : "")
                    }
                >
                    <div className="logo-bar">
                        <Link to="/">
                            {" "}
                            <img src={ "/images/ozsahinler_logo.png"} />{" "}
                        </Link>
                    </div>
                    <li
                        className={
                            (isHamburgerMenuOpen
                                ? "header-nav-li-open-menu"
                                : "") + " header-first-el"
                        }
                    >
                        <Link onClick={handleCloseHamburgerMenu} to="/">
                            {t("menu.home")}
                        </Link>
                    </li>
                    <li
                        className={
                            isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""
                        }
                    >
                        <Link onClick={handleCloseHamburgerMenu} to="/hakkimizda">
                            {t("menu.about-us")}
                        </Link>
                    </li>
                    <li
                        className={
                            isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""
                        }
                    >
                        <Link
                            onClick={()=> {
                                handlePopoverItemClick();
                                handleCloseHamburgerMenu();
                            }}
                            to="/urunler"
                            {...(!isSmallScreen ? bindHover(popupState) : {})}
                        >
                            {t("menu.products")} <FontAwesomeIcon icon={faAngleDown} style={{display: isHamburgerMenuOpen ? "none" : "inherit"}}/>
                        </Link>
                        <HoverPopover
                            style={{display: isHamburgerMenuOpen || isSmallScreen ? "none" : "inherit"}}
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <Typography>
                                <div className="header-products">
                                    <div className="header-products-product">
                                        <Link 
                                            to="/urunler/ahsap-kesen-celik-serit-testereler"
                                            onClick={handlePopoverItemClick}
                                        >
                                            <img src={ "/images/5J3A3907.jpg"} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    {t("home-page.wood-cutting-steel-band-saw")}
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link 
                                            to="/urunler/et-ve-balik"
                                            onClick={handlePopoverItemClick}
                                        >
                                            <img src={ "/images/5J3A4504.jpg"} alt="" />
                                            <div className="header-products-texts">
                                                <h3>{t("home-page.meat-and-fish-saw")}</h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link 
                                            to="/urunler/m42-bi-metal-serit-testere"
                                            onClick={handlePopoverItemClick}
                                        >
                                            <img src={ "/images/5J3A4482.jpg"} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    {t("home-page.m42-bi-metal-steel-band-saw")}
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link 
                                            to="/urunler/disleri-sertlestirilmis-testereler"
                                            onClick={handlePopoverItemClick}
                                        >
                                            <img src={ "/images/5J3A4461.jpg"} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    {/* Dişleri Sertleştirilmiş, */}
                                                    {t("home-page.tempered-and-bevelled-wood-cutting-steel-band-saw")}
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link 
                                            to="/urunler/tct-elmas-uclu-daire-testereler"
                                            onClick={handlePopoverItemClick}
                                        >
                                            <img src={ "/images/5J3A4277.jpg"} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    {/* TCT Elmas Uçlu Daire
                                                    Testereler */}

                                                    {t("home-page.tct-diamond-edged-disc-saw")}
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link 
                                            to="/urunler/ahsap-testere-bileme-taslari"
                                            onClick={handlePopoverItemClick}
                                        >
                                            <img src={ "/images/5J3A4461.jpg"} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    {/* Ahşap Testere Bileme Taşları */}
                                                    {t("home-page.wooden-saw-blades")}
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </Typography>
                        </HoverPopover>
                    </li>
                    <li
                        onClick={handleCloseHamburgerMenu}
                        className={
                            isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""
                        }
                    >
                        <Link  to="/hizmetler">
                            {t("menu.services")}
                        </Link>
                    </li>
                    <li
                        onClick={handleCloseHamburgerMenu}
                        className={
                            isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""
                        }
                    >
                        <Link  to="/iletisim">
                            {t("menu.contact")}
                        </Link>
                    </li>
                    {/* language dropdown with i18n and flags as well, turkish and english, with material ui */}
                    <li className={isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""}>
                        {/* {i18n.language} */}
                        {/* <select onChange={handleLanguageChange}>
                            <option value="tr">Türkçe</option>
                            <option value="en">English</option>
                        </select> */}

                        <Select
                            // value={i18n.language}
                            value={normalizeLanguage(i18n.language) || "tr"}
                            // value={i18n.language || "tr"}
                            label={t("menu.language")}
                            onChange={handleLanguageChange}
                            //remove the underline
                            style={{ textDecoration: "none", color: "gray", width: "120px" }}
                            MenuProps={{ disableScrollLock: true ,    PaperProps: {
                                style: {
                                    maxWidth: '140px',  // Set maximum width for the dropdown
                                    // width: '120px',     // Ensure the dropdown is fixed at 100px
                                },
                            }}
                        }

                        >
                            {/* set width to 100px */}
                            <MenuItem  value="tr">🇹🇷 {t("languages.turkish")}</MenuItem>
                            <MenuItem  value="en">🇬🇧 {t("languages.english")}</MenuItem>
                            <MenuItem  value="de">🇩🇪 {t("languages.german")}</MenuItem>
                            <MenuItem  value="zh">🇨🇳 {t("languages.mandarin")}</MenuItem>
                            <MenuItem  value="es">🇪🇸 {t("languages.spanish")}</MenuItem>
                            <MenuItem  value="fr">🇫🇷 {t("languages.french")}</MenuItem>
                        </Select>
                    </li>



                    <div className="header-nav-hamburger">
                        <FontAwesomeIcon
                            onClick={handleHamburgerClick}
                            color="white"
                            icon={faBars}
                            size="2x"
                        />
                    </div>
                </nav>
            </header>
        </>
    );
};

export default HomeHeader;
